
a {
    color: #DDE5D8;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
}

a:hover {
    color: #C4C4C2;
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
}

.bold {
    font-weight: bold;
}
.green {
    color: #449600;
}

.red {
    color: red;
}

.body_div {
    width: 100%;
    height: 100%;
}
body
{
    height: 100%;
    background-color: #DBF2FE;
    font-family: helvetica, verdana, tahoma, arial;
    font-size: 0.95em;
}

h1
{
    margin-bottom: 20px;
}

ul
{
    list-style-type: disc;
    padding-left: 15px;
}

.maindiv
{
    width: 800px;
    background-color: #59DA12;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    min-height: 500px;
    height: 100%;
}

.maintable {
    height: 500px;
    width: 800px;
    padding: 0px;
    border-spacing: 0px;
    border: 1px solid #8C887E;
}

.mainheader {
    height: 65px;
    background: url('../images/layout/delta.png') no-repeat #FFFFFF;
}

.mainrow {
    height: 100%;
}
.topmenu {
    background: #CB7300;
    text-align: center;
}

.topmenu p {
    margin: 1em auto;
}

input {
    /*width: 140px;*/
}

.label {
    width: 100px;
    text-align: left;
}

img
{
    border: none;
}

.left_column
{
    width: 18%;
    text-align: center;
}

/********/
/* Menu */
/********/

.menu_main {
    width: 100%;
    padding: 0px;
    margin: 0px;
    text-align: left;
}

.menu {
    background: url('../images/box/box_spacer.png');
    width: 100%;
    text-align: center;
}

.menu_left {
    background: url('../images/box/box_cl.png') repeat-y;
    /*width: 193px;*/
    padding-left: 15px;
    width: 80%;
}

.menu_right {
    background: url('../images/box/box_cr.png') repeat-y right 0;
    /*width: 193px;*/
    padding-right: 19px;
    width: 100%;
}

div.menu p{
    margin: 0px;
    line-height: 1.6em;
}

div.menu a{
    text-decoration: none;
    text-weight: normal;
    color: #7F7F7F;
}

div.menu a:hover{
    text-decoration: none;
    text-weight: bold;
    color: #E5E5E5;
}

.menu_top_left {
    height: 16px;
    padding-left: 15px;
    width: 80%;
    background: url('../images/box/box_tl.png') no-repeat 0 0;
}

.menu_top_right {
    height: 16px;
    padding-right: 19px;
    /*width: 193px;*/
    width: 100%;
    background: url('../images/box/box_tr.png') no-repeat right 0;
}

.menu_top {
    height: 16px;
    /*width: 193px;*/
    margin-right: 19px;
    width: 100%;
    background: url('../images/box/box_t.png') repeat-x;
    top: 0px;
}



.menu_bottom_left {
    height: 17px;
    padding-left: 15px;
    width: 80%;
    background: url('../images/box/box_bl.png') no-repeat 0 0;
    top: 100%;
}

.menu_bottom {
    height: 17px;
    width: 100%;
    margin-right: 19px;
    background: url('../images/box/box_b.png') repeat-x 0 0;
}

.menu_bottom_right {
    height: 17px;
    padding-right: 19px;
    width: 100%;
    background: url('../images/box/box_br.png') no-repeat right 0;
}

/***************/
/* Main column */
/***************/

td.main_column{
    text-align: center;
    width: 100%;
    padding: 3px;
}
div.main_column_left{
    width: 100%;
    background: url('../images/layout/layout_l.jpg') repeat-y 0 0;
}

div.main_column_right{
    width: 100%;
    background: url('../images/layout/layout_r.jpg') repeat-y right 0;
}

div.main_column_top{
    height: 29px;
    top: 0px;
    background: url('../images/layout/layout_tc.jpg') repeat-x;
    margin-bottom: 6px;
}

div.main_column_top_left{
    height: 29px;
    width: 100%;
    background: url('../images/layout/layout_tl.jpg') no-repeat 0 0;
    margin-bottom: 6px;
}

div.main_column_top_right{
    height: 29px;
    width: 100%;
    top: 0px;
    background: url('../images/layout/layout_tr.jpg') no-repeat right 0;
    margin-bottom: 6px;
}

div.main_column_bottom{
    height: 36px;
    width: 100%;
    background: url('../images/layout/layout_bc.jpg') repeat-x 0 0;
}

div.main_column_bottom_left{
    height: 36px;
    width: 100%;
    background: url('../images/layout/layout_bl.jpg') no-repeat 0 0;
}

div.main_column_bottom_right{
    height: 36px;
    width: 100%;
    background: url('../images/layout/layout_br.jpg') no-repeat right 0;
}

.data_odd {
    font-size: 0.88em;
    background-color: #8F8F8F;
    text-align: center;
}

.data_even {
    font-size: 0.88em;
    background-color: #E1DBDB;
    /*filter:alpha(opacity=50);
    opacity: 0.5;*/
    text-align: center;
}

.data_even td a {
    color: #BBC3B6;
}

.data_even td a:hover {
    color: #A2A2A0;
}

.error {
    font-size: 0.85em;
    color: #FA0000;
}

.good {
    font-size: 0.85em;
    color: #00FA00;
}


/**********/
/* Slajdy */
/**********/

.slajd_table
{
    align: center;
    margin-left: auto;
    margin-right: auto;
    width: 798px;
    border: none;
}

.slajd_filler
{
    width: 13%;
    background-color: #FA9819;
}

.slajd_title_row
{
    height: 75px;
    background-color: #FA9819;
    color: #000000;
}

.slajd_title_cell
{
    text-align: center;
    padding: 0px;
    font-family: Tahoma, Verdana, Arial;
    font-size: 160%;
}

.slajd_main_div {
    width: 100%;
    min-height: 450px;
}

.slajd_main_div p, .slajd_main_div ul, .slajd_main_div li {
    margin: 0px auto;
}
.slajd_body
{
    font-family: Tahoma, Verdana, Arial;
    font-size: 1.3em;
    border-top: solid 3px #000000;
    border-bottom: solid 3px #000000;
    padding: 6px;
}

.slajd_footer
{
    height: 75px;
    text-align: right;
    padding-right: 20px;
    background-color: #FA9819;
}

.slajd_logo
{
    float: left;
    margin-left: 3px;
}

.nr_slajdu {

    float: right;
    margin-right: 10px;
    margin-top: 27px;
}

.dalej_form {
    text-align: center;
}

.dzial_title
{
    color: #000000;
    font-size: 300%;
    text-align: center;
    align: center;
    padding: 40px;
}

.body_title
{
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
}

.image_right
{
    float: right;
    margin-left: 7px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 0.6em;
    color: #666564;
    text-align: right;
}

.image_right_hidden
{
    float: right;
    margin-left: 7px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 0.6em;
    color: #666564;
    text-align: right;
    visibility: hidden;
}

.image_center_hidden
{
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 0.6em;
    color: #666564;
    text-align: center;
    visibility: hidden;
}

.image_left_hidden
{
    float: left;
    margin-right: 7px;
    margin-bottom: 10px;
    font-size: 0.6em;
    color: #666564;
    margin-top: 20px;
    text-align: right;
    visibility: hidden;
}

.image_left
{
    float: left;
    margin-right: 7px;
    margin-bottom: 10px;
    font-size: 0.6em;
    color: #666564;
    text-align: right;
    margin-top: 20px;
}

div.slajd_list {
    font-size: 0.9em;
    text-align: justify;
    padding-top: 5px;
    margin-top: 5px;
}

div.slajd_list_hidden {
    font-size: 0.9em;
    text-align: justify;
    padding-top: 5px;
    visibility: hidden;
}

p.slajd_list {
    padding-top: 7px;
}

p.slajd_list_hidden {
    padding-top: 7px;
    visibility: hidden;
}
p.slajd_list_xl {
    padding-top: 20px;
    font-size: 1.3em;
    font-weight: bold;
}

p.slajd_list_xl_hidden {
    padding-top: 20px;
    font-size: 1.3em;
    font-weight: bold;
    visibility: hidden;
}

li.hidden
{
    visibility: hidden;
}

.body_slajd_table
{
    margin-left: auto;
    margin-right: auto;
    border: 0;
}

/**************/
/* Slajdy_end */
/**************/

.mainfooter{
    font-size: 0.85em;
    text-align: center;
    text-weight: bold;
    height: 22px;
    width: 800px;
    background: url('../images/layout/layout_21.jpg') no-repeat;
    padding-top: 3px;
    color: #E5E5E5;
}

.logout {
    padding-top: 4px;
    left: 100%;
    top: 0%;
    width: 75px;
    heigth: 100%;
    float:right;
    text-size: 0.88em;
}

input.textfield {
    width: 150px;
}



/****************/
/* Panel szkoly */
/****************/

.table_kursy_header td {
    border-bottom: 1px solid #000000;
    text-align: center;
}

.kursy_list_header {
    font-size: 1.3em;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 24px;
}

.kursy_list {
    width: 100%;
    margin-bottom: 30px;
}

table.kursy_list td:first-child {
    text-align: left;
}

.table_kursy_header {

}

.mainbox {
    min-width: 450px;
    padding-left: 10px;
    padding-right: 10px;
}

.mainbox_details {
    text-align: left;
    width: 93%;
    min-width: 450px;
    padding-left: 20px;
    padding-right: 20px;
}

.form_row {
    margin: 10px 0px 10px 0px;
}

.zalogowany {
    float: left;
    font-size: 0.7em;
    padding-top: 10px;
    top: 0%;
    height: 100%;
    padding-left: 5px;
    text-align: left;
}

/*********************/
/** Message Box div **/
/*********************/

#message_div {
    position: absolute;
    width: 450px;
    left: 32%;
    top: 0;
    visibility: hidden;
}

#message_div_body {
    padding-top: 10px;
    text-align: center;
    height: 40px;
    background-color: #C5B38A;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 20px;
    border: 3px #000000 solid;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

div.msgbox_left{
    width: 100%;

}

div.msgbox_right{
    width: 100%;

}

div.msgbox_bottom{
    height: 36px;
    margin-left: 25px;
    margin-right: 25px;
    width: 88.9%;

}

div.msgbox_bottom_left{
    height: 36px;
    width: 100%;

}

div.msgbox_bottom_right{
    height: 36px;
    width: 100%;

}

.faktura {
    display: none;
}

.hd {
    height: 35px;
    font-weight: bold;
}

p.modul {
    margin: 1em auto;
    padding: 0px 15px;
}

h2.modul {
    margin: 1em auto;
    font-size: 22px;
}

/*************************/
/** Buttony na slajdzie **/
/*************************/
#btnDalej {
    width: 150px;
    height: 50px;
    border-color: #0000FF;
    background: #57C300;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

#btnDalej:disabled {
    border-color: #565656;
    background: #35A100;
    color: #565656;
}

#btnPowrot {
    width: 150px;
    height: 50px;
    border-color: #0000FF;
    background: #8B9582;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}
/*****************************/
/** Buttony na slajdzie end **/
/*****************************/

.submenu {
    margin: 12px auto;
    font-size: 11px;
}

.submenu a {
    margin: auto 5px;
}

#atd_elearningbundle_trener {
    width: 500px;
    margin: auto;
    height: 120px;
    margin-top: 25px;
}

#atd_elearningbundle_trener > div {
    clear: both;
}

#atd_elearningbundle_trener select, #atd_elearningbundle_trener label, #atd_elearningbundle_trener input[type=text], #atd_elearningbundle_trener input[type=password]{
    display: block;
    float: left;
    margin-right: 15px;
    width: 200px;
    margin-bottom: 5px;
}

#atd_elearningbundle_trener select {width: 350px}

#atd_elearningbundle_trener label {
    width: 200px;
    text-align: right;
}

#form_errors ul {
    margin: 3px auto;
    list-style-type: none;
    width: 300px;
    text-align: left;
    color: red;
}

.szczegoly_kursu {
    margin: auto;
}
.szczegoly_kursu td {
    text-align: left;
}
.szczegoly_kursu td:first-child {
    width: 250px;
    font-weight: bold;
    text-align: right;
    padding-right: 10px;
}

.szczegoly_kursu .kursanci td, .szczegoly_kursu .kursanci th {
    border-bottom: 1px solid black;
}

.szczegoly_kursu .kursanci td {
    padding-top: 10px;
    padding-bottom: 10px;
}

#kurs_filter {
    border: 1px solid black;
    border-radius: 5px;
    margin: 15px 0px;
    padding: 10px;
}

#kurs_filter input[type="text"], #kurs_filter select {
    width: 200px;
}

#atd_elearningbundle_filtr div {
    width: 350px;
    float: left;
    margin: 10px 0px;
}

#kurs_filter label {
    width: 100px;
    display: block;
    float: left;
    text-align: right;
}

#cookies {
    position: absolute;
    top: 0px;
    width: 100%;
    padding: 8px 0px;
    background: #efefef;
    font-size: 13px;
    text-align: center;
}

#cookies_txt a {
    color: #dd2222;
}

#cookies_txt a:hover {
    color: #ff4444;
}
.modul_170 p {
    text-align: justify;
}

.modul_170 img.image_left {
    margin-right: 20px;
}
